<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Pengaturan Admin</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2">
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units"
                v-model="unit"
                :loading="loadingUnit"
                placeholder="Unit Utama"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_utama_id"
                clearable
                @change="changeUnit(1)"
                @click:clear="changeUnit(1)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units2"
                v-model="unit2"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_2_id"
                clearable
                @change="changeUnit(2)"
                @click:clear="changeUnit(2)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units3"
                v-model="unit3"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_3_id"
                clearable
                @change="changeUnit(3)"
                @click:clear="changeUnit(3)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units4"
                v-model="unit4"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_4_id"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="px-0">
              <v-text-field
                placeholder="Cari"
                v-model="search"
                outlined
                dense
                hide-details
                clearable
                prepend-inner-icon="search"
                class="mx-2"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions class="py-0">
            <v-btn
              text
              class="text-capitalize caption headline-color"
              color="primary"
              @click="visible = true"
              >Buat User<v-icon class="ml-2" color="primary"
                >add_circle</v-icon
              ></v-btn
            >
            <v-spacer />
            <v-switch
              class="ma-0 ml-5 mb-2"
              v-model="includeUser"
              label="Munculkan Pegawai"
              color="primary"
              hide-details
            ></v-switch>
          </v-card-actions>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="users"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
          >
            <!-- <template v-slot:[`item.username`]="{ item }">
              <span
                class="hover-primary"
                @click="getToPage('employee.detail')"
                >{{ item.username }}</span
              >
            </template> -->
            <template v-slot:[`item.unit`]="{ item }">
              <div class="py-1" style="min-width: 150px">
                <p class="mb-0">
                  <b>Unit Utama: </b>{{ item.unit_utama_nama }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja 2: </b>{{ item.unit_kerja_2_nama }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja 3: </b>{{ item.unit_kerja_3_nama }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja 4: </b>{{ item.unit_kerja_4_nama }}
                </p>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                x-small
                color="#297BBF"
                class="mr-2 my-1 white--text"
                @click="handleEdit(item)"
                >Edit</v-btn
              >
              <v-btn
                x-small
                color="#FBB005"
                class="mr-2 my-1 white--text"
                @click="handleDelete(item)"
                >Hapus</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="600">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Buat User
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="4" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Username</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-1">
              <v-text-field
                placeholder="Masukan Nama Pegawai"
                :value="formInput"
                :disabled="form.id != null"
                outlined
                dense
                :rules="[v => !!v || 'Username harus diisi']"
                @click="$refs.employeeSearch.change(true)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="form.id">
            <v-col cols="12" class="pb-5 pt-0 px-7">
              <span class="caption">Kata Sandi: </span>
              <span
                class="caption"
                :class="isChange ? 'hover-error' : 'hover-accent'"
                @click="handleReset"
                >{{
                  isChange ? "Cancel Request" : "Request Reset Password"
                }}</span
              >
            </v-col>
          </v-row>
          <template v-if="(isChange && form.id) || form.id == null">
            <v-row>
              <v-col cols="4" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Password</v-subheader
                >
              </v-col>
              <v-col cols="8" class="py-1">
                <v-text-field
                  v-model="form.password"
                  outlined
                  dense
                  :append-icon="e0 ? 'visibility' : 'visibility_off'"
                  @click:append="() => (e0 = !e0)"
                  :type="e0 ? 'password' : 'text'"
                  :rules="[v => !!v || 'Password harus diisi']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Konfirmasi Password</v-subheader
                >
              </v-col>
              <v-col cols="8" class="py-1">
                <v-text-field
                  v-model="form.password_confirmation"
                  outlined
                  dense
                  :append-icon="e1 ? 'visibility' : 'visibility_off'"
                  @click:append="() => (e1 = !e1)"
                  :type="e1 ? 'password' : 'text'"
                  :rules="confirmPasswordRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
          <!-- <v-row>
            <v-col cols="4" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Utama</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-1">
              <v-select
                placeholder="- Semua Unit Utama -"
                v-model="form.unit_utama_id"
                :items="units"
                :loading="loadingUnit"
                outlined
                dense
                clearable
                item-text="singkatan"
                item-value="unit_utama_id"
                @change="changeUnitForm(1)"
                @click:clear="changeUnitForm(1)"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Kerja</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-1">
              <v-select
                placeholder="- Semua Unit Kerja -"
                v-model="form.unit_kerja_2_id"
                :items="units2Form"
                :loading="loadingUnit"
                outlined
                dense
                clearable
                item-text="singkatan"
                item-value="unit_kerja_2_id"
              ></v-select>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="4" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Level</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-1">
              <v-autocomplete
                v-model="form.role"
                :items="roles"
                :loading="loadingRole"
                outlined
                dense
                :rules="[v => !!v || 'Level harus diisi']"
                item-text="name"
                item-value="value"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <EmployeeSearchDialog
      ref="employeeSearch"
      :lists="[form]"
      value="NIP"
      @handle-pick="pickItem"
      singlePick
    />
  </div>
</template>
<script>
import UserService from "@/services/resources/user.service";
import DataUtamaService from "@/services/resources/data.utama.service";
import { mapSummaryRootField } from "@/store/helpers";

const EmployeeSearchDialog = () => import("@/components/Dialog/EmployeeSearch");

export default {
  components: {
    EmployeeSearchDialog
  },
  data() {
    return {
      visible: false,
      valid: false,
      loading: false,
      loadingRole: false,
      loadingUnit: false,
      formLoading: false,
      isChange: false,
      includeUser: false,
      e0: true,
      e1: true,
      unit: null,
      unit2: null,
      unit3: null,
      unit4: null,
      units: [],
      units2: [],
      units3: [],
      units4: [],
      roles: [],
      search: "",
      headers: [
        { text: "USERNAME", value: "username", align: "left" },
        { text: "NAMA", value: "name", align: "left" },
        {
          text: "UNIT KERJA",
          value: "unit",
          sortable: false,
          align: "left"
        },
        { text: "LEVEL", value: "role_name", align: "left" },
        { text: "", value: "actions", sortable: false, align: "left" }
      ],
      users: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["id"],
        sortDesc: [false]
      },
      confirmPasswordRules: [
        v => !!v || "Konfirmasi Password harus diisi",
        v => (v && v == this.form.password) || "Password harus sesuai"
      ],
      rowsPerPageItems: [10, 20, 30, 40],
      form: {
        id: null,
        username: null,
        name: null,
        password: null,
        password_confirmation: null,
        role: null,
        unit_utama_id: null,
        unit_kerja_2_id: null
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getListUser);
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListUser);
    },
    visible(val) {
      if (!val) {
        this.isChange = false;
        this.form.id = null;
        this.form.username = null;
        this.form.name = null;
        this.form.password = null;
        this.form.password_confirmation = null;
        this.form.role = null;
        this.form.unit_utama_id = null;
        this.form.unit_kerja_2_id = null;
        this.$refs.form.reset();
      }
    }
  },
  computed: {
    paginationProperty() {
      return [
        this.search,
        this.unit,
        this.unit2,
        this.unit3,
        this.unit4,
        this.includeUser
      ].join();
    },
    ...mapSummaryRootField({
      isPresensi: "setting.isPresensi"
    }),
    formInput() {
      if (this.form.username == null) return;
      if (this.form.name == null) return;
      return `${this.form.username} - ${this.form.name}`;
    }
  },
  methods: {
    pickItem(value) {
      this.form.username = value.NIP;
      this.form.name = value.nama;
    },
    changeUnit(type = 2) {
      if (type == 1) {
        this.units2 = this.units.find(
          d => d.unit_utama_id == this.unit
        )?.unit_kerja2;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 2) {
        this.units3 = this.units2.find(
          d => d.unit_kerja_2_id == this.unit2
        )?.unit_kerja3;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 3) {
        this.units4 = this.units3.find(
          d => d.unit_kerja_3_id == this.unit3
        )?.unit_kerja4;
        this.unit4 = null;
      }
    },
    populateUnit() {
      this.units2 = this.units.find(d =>
        d.unit_utama_id.includes(this.unit)
      )?.unit_kerja2;
      this.units3 = this.units2.find(
        d => d.unit_kerja_2_id == this.unit2
      )?.unit_kerja3;
      this.units4 = this.units3.find(
        d => d.unit_kerja_3_id == this.unit3
      )?.unit_kerja4;
    },
    changeUnitForm(type = 1) {
      if (type == 1) {
        this.units2Form = this.units.find(
          d => d.unit_utama_id == this.form.unit_utama_id
        )?.unit_kerja2;
        this.form.unit_kerja_2_id = null;
      }
    },
    handleReset() {
      this.form.password = null;
      this.form.password_confirmation = null;
      this.isChange = !this.isChange;
    },
    handleEdit(item) {
      this.visible = true;
      this.getDetailUser(item.id);
    },
    handleDelete(item) {
      this.$confirm({
        title: "Confirm",
        message: `Apakah anda yakin akan menghapus data ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            this.deleteUser(item.id);
          }
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        let payload = {
          id: this.form.id,
          action: this.form.id ? 2 : 1,
          username: this.form.username,
          platform: this.isPresensi ? "presensi" : "simpeg",
          name: this.form.name,
          password: this.form.password || null,
          password_confirmation: this.form.password_confirmation || null,
          role: this.form.role,
          unit_utama_id: this.form.unit_utama_id,
          unit_kerja_2_id: this.form.unit_kerja_2_id
        };
        this.saveUser(payload);
      }
    },
    // Service
    async getListRole() {
      try {
        this.loadingRole = true;
        await UserService.getListRole({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.roles = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingRole = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.units = data;

              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListUser() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await UserService.getList({
          platform: this.isPresensi ? "presensi" : "simpeg",
          include_user: this.includeUser ? 1 : 0,
          filter: {
            search: this.search,
            unit_utama_id: this.unit || "",
            unit_kerja_2_id: this.unit2 || "",
            unit_kerja_3_id: this.unit3 || "",
            unit_kerja_4_id: this.unit4 || ""
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let users = list;
              users.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.users = users;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveUser(data) {
      try {
        this.formLoading = true;
        await UserService.save(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListUser();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailUser(id) {
      try {
        this.loading = true;
        await UserService.getDetail(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.form = {
                id: data.id,
                username: data.username,
                name: data.name,
                password: data.password,
                password_confirmation: data.password_confirmation,
                role: data.role,
                unit_utama_id: data.unit_utama_id,
                unit_kerja_2_id: data.unit_kerja_2_id
              };
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteUser(payload) {
      try {
        this.formLoading = true;
        await UserService.delete(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListUser();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListRole();
    this.getListUnit();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
#card-custom .v-label {
  font-size: 0.775rem;
}
</style>
